
.guide-txt {
    padding: 24px 0 50px;
}
@media (min-width: 1024px) {
    .guide-txt {
        padding: 0 60px;
    }
}
@media (min-width: 1300px) {
    .guide-txt {
        padding: 0 80px;
    }
}
