
.icon-next {
    display: inline-block;
    max-width: 20px;
    margin: 0 6px;
    transform: translateY(2px);
}
@media (min-width: 768px) {
    .icon-next {
        display: inline-block;
        max-width: 26px;
        margin: 0 10px;
        transform: initial;
    }
}
@media (min-width: 1024px) {
    .icon-next {
        margin: 0 20px;
    }
}
